import React, { forwardRef, Ref, useEffect, useState } from "react";
import "../Stylesheets/MusicSection.css"
import "../fonts.css"
import jacketImage from "../img/GENESIS_j-sh.png"
import bgDiscIcon from "../img/20240410disk02.png"
import jacketImage02 from "../img/GENESIS02_j.png"
import bgDiscIcon02 from "../img/20241031disk.png"

const MusicSection = forwardRef<HTMLDivElement, MusicSectionProps>((props, ref) => {
    
    const currentPosition = props.currentPosition
    const [videoWidth, setVideoWidth] = useState<number>(720)
    const [stackTitleClass, setStackTitleClass] = useState<string>("")
    const [detailTextClass, setDetailTextClass] = useState<string>("")
    const [jpTextClass, setJpTextClass] = useState<string>("")
    const [sellerButtonClass, setSellerButtonClass] = useState<string>("")


    useEffect(() => {
        window.addEventListener("resize", handleResizing)

        return () => {
            window.removeEventListener("resize", handleResizing)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleResizing()
    }, [])

    function handleResizing() {
        if (window.innerWidth > 1920) {
            setVideoWidth(720)
            setStackTitleClass("stack-title-text")
            setDetailTextClass("detail-text")
            setJpTextClass("japanese-text")
            setSellerButtonClass("seller-button")
        }
        else if (window.innerWidth > 1660) {
            setVideoWidth(660)
            setStackTitleClass("stack-title-text-bp1")
            setDetailTextClass("detail-text-bp1")
            setJpTextClass("japanese-text-bp1")
            setSellerButtonClass("seller-button-bp1")
        }
        else if (window.innerWidth > 1000) {
            setVideoWidth(480)
            setStackTitleClass("stack-title-text-bp2")
            setDetailTextClass("detail-text-bp2")
            setJpTextClass("japanese-text-bp2")
            setSellerButtonClass("seller-button-bp2")
        }
    }


    const discStyle: React.CSSProperties = {
        position: "absolute",
        width: "45%",
        left: "40%",
        opacity: "0.2",
        zIndex: "-1",
        transform: `rotate(${currentPosition / 10}deg)`
    }
    
    const discStyle2: React.CSSProperties = {
        position: "absolute",
        width: "45%",
        left: "40%",
        opacity: "0.2",
        zIndex: "-1",
        transform: `rotate(-${currentPosition / 10}deg)`
    }

    return (
        <section className="music-section" ref={ref}>
            <div className="music-album-container">
                <div className="icon-background-music" style={discStyle}>
                    <img className="disc-icon" src={bgDiscIcon02}></img>
                </div>
                <div className="music-title-text">NOW ON SALE!</div>
                <div className="album-information-box">
                    <div className="jacket-container">
                        <img className="album-jacket" src={jacketImage02}></img>
                        <div className="seller-button-container">
                            <a target="_blank" href="https://axtorm.bandcamp.com/album/genesis-re-build-02"
                               className={sellerButtonClass}>Bandcamp</a>
                            <a href="https://linkco.re/Hs1vg009" target="_blank"
                                 className={sellerButtonClass}>Streaming
                            </a>
                            <a target="_blank"
                               href="https://www.tanocstore.net/shopdetail/000000003891/"
                               className={sellerButtonClass}>TANO*C STORE</a>
                            <a target="_blank" href="https://www.diverse.direct/axtorm-music/axt-cd-003/"
                               className={sellerButtonClass}>Diverse Direct</a>
                        </div>
                    </div>
                    <div className="description-stack-container">
                        <div className="japanese-text-container">
                        <div className={jpTextClass + " rebuild02subcolor"}>ボーダレスに響く、音楽で世界がつながる。</div>
                        </div>
                        <div className={stackTitleClass + " rebuild02titlecolor"}>GENESiS RE:BUiLD 02
                            {/*  <img className="stack-title-icon" src={musicBgIcon}></img> */}
                        </div>
                        <div className={detailTextClass}>Streaming now available!</div>
                        <div className="videos-container">
                            <iframe width={videoWidth} height={videoWidth * 0.5625}
                                    src="https://www.youtube.com/embed/ctb3urRDuWI?si=Iq4cEh8ggFybFdpo"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="music-album-container">
                <div className="icon-background-music" style={discStyle}>
                    <img className="disc-icon" src={bgDiscIcon}></img>
                </div>
                <div className="album-information-box">
                    <div className="jacket-container">
                        <img className="album-jacket" src={jacketImage}></img>
                        <div className="seller-button-container">
                            <a target="_blank" href="https://axtorm.bandcamp.com/album/genesis-re-build-01"
                               className={sellerButtonClass}>Bandcamp</a>
                            <a target="_blank" href="https://linkco.re/D5zvn4Gn"
                               className={sellerButtonClass}>Streaming</a>
                            <a target="_blank"
                               href="https://www.tanocstore.net/shop/shopdetail.html?brandcode=000000003739"
                               className={sellerButtonClass}>TANO*C STORE</a>
                            <a target="_blank" href="https://www.diverse.direct/axtorm-label/axt-cd-001002/"
                               className={sellerButtonClass}>Diverse Direct</a>
                        </div>
                    </div>
                    <div className="description-stack-container">
                        <div className="japanese-text-container">
                        <div className={jpTextClass}>その意志は決して枯れたりしない。</div>
                            <div className={jpTextClass}>苦悩の果てに、いつか花咲く未来を夢見て。</div>
                        </div>
                        <div className={stackTitleClass}>GENESiS RE:BUiLD 01
                            {/*  <img className="stack-title-icon" src={musicBgIcon}></img> */}
                        </div>
                        <div className="videos-container">
                            <iframe width={videoWidth} height={videoWidth * 0.5625}
                                    src="https://www.youtube.com/embed/F0lonzZVODQ?si=1FWCuD4zGwDfUirq"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                            <iframe width={videoWidth} height={videoWidth * 0.5625}
                                    src="https://www.youtube.com/embed/VP6xqvLdfhY?si=R5f-tpvZMb2e8qNA"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

})

export default MusicSection