import React, { forwardRef, Ref, useEffect, useState } from "react";
import "../StylesheetsMobile/MusicSectionMobile.css"
import "../fonts.css"
import jacketImage from "../img/GENESIS_j-sh.png"
import jacketImage02 from "../img/GENESIS02_j.png"
import bgDiscIcon2 from "../img/20240410disk.png"
import bgDiscIcon from "../img/20240410disk02.png"
import bgDiscIcon3 from "../img/20241031disk.png"


const MusicSectionMobile = forwardRef<HTMLDivElement, MusicSectionProps>((props, ref) => {

    const currentPosition = props.currentPosition
    const [videoWidth, setVideoWidth] = useState<number>(640)
    const [stackTitleClass, setStackTitleClass] = useState<string>("")
    const [detailTextClass, setDetailTextClass] = useState<string>("")
    const [jpTextClass, setJpTextClass] = useState<string>("")
    const [sellerButtonClass, setSellerButtonClass] = useState<string>("")
    const albumList: Array<Album> = []
    

    
    useEffect(() => {
        window.addEventListener("resize", handleResizing)

        return () => {
            window.removeEventListener("resize", handleResizing)
        }
    }, [window.innerWidth])

    useEffect(() => {
        handleResizing()
    }, [])

    function handleResizing() {
        if (window.innerWidth > 640) {
            setVideoWidth(640)
            setStackTitleClass("stack-title-text-mobile")
            setDetailTextClass("detail-text-mobile")
            setJpTextClass("japanese-text-mobile")
            setSellerButtonClass("seller-button-mobile")
        }
        else if (window.innerWidth > 480) {
            setVideoWidth(480)
            setStackTitleClass("stack-title-text-mobile-bp1")
            setDetailTextClass("detail-text-mobile-bp1")
            setJpTextClass("japanese-text-mobile-bp1")
            setSellerButtonClass("seller-button-mobile-bp1")
        }
        else {
            setVideoWidth(320)
            setStackTitleClass("stack-title-text-mobile-bp2")
            setDetailTextClass("detail-text-mobile-bp2")
            setJpTextClass("japanese-text-mobile-bp2")
            setSellerButtonClass("seller-button-mobile-bp2")
        }
    }
    
    function GenerateCurrentAlbums()
    {
    }

    function CreateAlbum(
        AlbumName: string,
        saleLinks: Array<string>,
        videoLinks: Array<string>,
        japaneseCatchcopy: HTMLDivElement,
        albumJacketLink: string,
        discImageLink: string): Album
    {
        let newAlbum: Album;
        newAlbum.AlbumName = AlbumName
        newAlbum.SaleLinks = saleLinks
        newAlbum.VideoLinks = videoLinks
        newAlbum.AlbumJacketLink = albumJacketLink
        newAlbum.DiscImageLink = discImageLink
        return newAlbum;
    }
    
    

    const discStyle: React.CSSProperties = {
        position: "absolute",
        width: "90%",
        left: "5%",
        top: "5%",
        opacity: "0.2",
        zIndex: "-1",
        transform: `rotate(${currentPosition / 10}deg)`
    }

    const disc2Style: React.CSSProperties = {
        position: "absolute",
        width: "90%",
        left: "5%",
        top: '40%',
        opacity: "0.2",
        zIndex: "-2",
        transform: `rotate(-${currentPosition / 10}deg)`
    }

    return (
        <section className="music-section-mobile" ref={ref}>
            <div className="music-album-container">
                <div className="icon-background-music-mobile" style={discStyle}>
                    <img className="disc-icon-mobile" src={bgDiscIcon3}></img>
                </div>
                
                <div className="music-title-text-mobile">NOW ON SALE!</div>

                <div className="album-information-box-mobile">
                    <div className="japanese-text-container-mobile">
                        <div className={jpTextClass + " rebuild02subcolor"}>ボーダレスに響く、音楽で世界がつながる。</div>
                    </div>
                    <div className={stackTitleClass + " rebuild02titlecolor"}>GENESiS RE:BUiLD 02
                    </div>
                    <div className="jacket-container-mobile">
                        <img className="album-jacket-mobile" src={jacketImage02}></img>
                    </div>
                    <div className="seller-button-container-mobile">
                        <a target="_blank" href="https://axtorm.bandcamp.com/album/genesis-re-build-02"
                           className={sellerButtonClass}>Bandcamp</a>
                        <a href="https://linkco.re/Hs1vg009" target="_blank"
                             className={sellerButtonClass}>Streaming
                        </a>
                        <a target="_blank" href="https://www.tanocstore.net/shopdetail/000000003891/"
                           className={sellerButtonClass}>TANO*C STORE</a>
                        <a target="_blank" href="https://www.diverse.direct/axtorm-music/axt-cd-003/"
                           className={sellerButtonClass}>Diverse Direct</a>
                    </div>

                    <div className="videos-container-mobile">
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/ctb3urRDuWI?si=Iq4cEh8ggFybFdpo"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="music-album-container">
                <div className="icon-background-music-mobile" style={discStyle}>
                    <img className="disc-icon-mobile" src={bgDiscIcon}></img>
                </div>
                <div className="icon-background-music-mobile" style={disc2Style}>
                <img className="disc-icon-mobile" src={bgDiscIcon2}></img>
            </div>
                <div className="album-information-box-mobile">
                    <div className="japanese-text-container-mobile">
                        <div className={jpTextClass}>その意志は決して枯れたりしない。</div>
                        <div className={jpTextClass}>苦悩の果てに、いつか花咲く未来を夢見て。</div>
                    </div>
                    <div className={stackTitleClass}>GENESiS RE:BUiLD 01
                    </div>
                    <div className="jacket-container-mobile">
                        <img className="album-jacket-mobile" src={jacketImage}></img>
                    </div>
                    <div className="seller-button-container-mobile">
                        <a target="_blank" href="https://axtorm.bandcamp.com/album/genesis-re-build-01"
                           className={sellerButtonClass}>Bandcamp</a>
                        <a target="_blank" href="https://linkco.re/D5zvn4Gn"
                           className={sellerButtonClass}>Streaming</a>
                        <a target="_blank" href="https://www.tanocstore.net/shop/shopdetail.html?brandcode=000000003739"
                           className={sellerButtonClass}>TANO*C STORE</a>
                        <a target="_blank" href="https://www.diverse.direct/axtorm-label/axt-cd-001002/"
                           className={sellerButtonClass}>Diverse Direct</a>
                    </div>

                    <div className="videos-container-mobile">
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/F0lonzZVODQ?si=1FWCuD4zGwDfUirq"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                        <iframe width={videoWidth} height={videoWidth * 0.5625}
                                src="https://www.youtube.com/embed/VP6xqvLdfhY?si=R5f-tpvZMb2e8qNA"
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    )
})

export default MusicSectionMobile