import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Header from './Header';
import LandingSection from './LandingSection';
import AboutUsSection from './AboutUsSection';
import CompanySection from './CompanySection';
import MusicSection from './MusicSection';
import GamesSection from './GamesSection';
import Footer from './Footer';
import LandingSectionMobile from "../ComponentsMobile/LandingSectionMobile";
import HeaderMobile from "../ComponentsMobile/HeaderMobile";
import AboutUsSectionMobile from "../ComponentsMobile/AboutUsSectionMobile";
import CompanySectionMobile from "../ComponentsMobile/CompanySectionMobile";
import MusicSectionMobile from "../ComponentsMobile/MusicSectionMobile";
import GamesSectionMobile from "../ComponentsMobile/GamesSectionMobile";
import FooterMobile from "../ComponentsMobile/FooterMobile";

export default function MainHomePage() {

  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const [skipVideo, setSkipVideo] = useState<boolean>(false);   
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const [currentWidth, setCurrentWidth] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [navigatedHash, setNavigatedHash] = useState<string>("")
  const [locale, setLocale] = useState<Locale>(stringToLocale(searchParams.get("locale") || "en"))
  const location = useLocation()

  const mainRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null)
  const musicRef = useRef<HTMLDivElement>(null)
  const gamesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    handleScroll()
    handleResize()
    document.body.style.backgroundColor = "black"
  }, [])

  useEffect(() => {
    stringToLocale(checkLocale())
  }, [locale])


  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [window.innerWidth])


  useEffect(() => {

    window.addEventListener("scroll", handleScroll)

    return () => {

      window.removeEventListener("scroll", handleScroll)

    }
  }, [window.scrollY])

  useEffect(() => {
    if (location.hash) {
      setNavigatedHash(location.hash.split("#")[1])
    }
  }, [location.hash])

  useEffect(() => {
    if (navigatedHash) {
      setVideoEnded(true)
      setSkipVideo(true)
      scrollToSection(navigatedHash)
    }
  }, [navigatedHash, videoEnded])


  function handleScroll() {
    setCurrentPosition(window.scrollY)
  }
  function handleResize() {
    setCurrentWidth(window.innerWidth)
  }

  function stringToLocale(stringLocale: string): Locale {
    switch (stringLocale)
    {
      case "en": 
      case "jp":
      case "kr": 
      return stringLocale
      default: 
      return "en";
    }
  }

  function scrollToSection(section: string) {
    const refMap: Record<string, React.RefObject<HTMLDivElement>> = {
      main: mainRef,
      aboutUs: aboutUsRef,
      music: musicRef,
      games: gamesRef
    }
    const sectionRef = refMap[section]
    StartScroll(sectionRef)
  }
  
  function StartScroll(sectionRef :React.RefObject<HTMLDivElement>)
  {
    if (sectionRef && sectionRef.current) {
      const sectionTop = sectionRef.current.getBoundingClientRect().top + window.scrollY;
      const sectionHeight = sectionRef.current.offsetHeight;
      const targetPosition = sectionTop - (window.innerHeight / 6 - sectionHeight / 6)
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth"
      })
    }
    else {
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  }

  function checkLocale(): string {
    setNavigatedHash(location.hash.split("#")[1])
    const urlParam = searchParams.get("locale")
    const validParams = ["en", "jp", "kr"]
    if (urlParam) {
      if (validParams.includes(urlParam)) {
        setSearchParams({ locale: locale })
        return urlParam
      }
      else {
        setSearchParams({ locale: "en" })
        return "en"
      }
    }
    else {
      setSearchParams({ locale: "en" })
      return "en"
    }
  }
  return (
    currentWidth > 1100 ?
      <div className="App">
        <Header logoOverride="" focusedArea="none" isMobileAndNotMain={false} currentWidth={currentWidth} locale={locale} setLocale={setLocale} videoEnded={videoEnded} currentPosition={currentPosition} />
        <LandingSection locale={locale} skipVideo={skipVideo} videoEnded={videoEnded} setVideoEnded={setVideoEnded} currentPosition={currentPosition} />
        {videoEnded && <AboutUsSection locale={locale} currentPosition={currentPosition} ref={aboutUsRef} />}
        {videoEnded && <CompanySection locale={locale} />}
        {videoEnded && <MusicSection ref={musicRef} currentPosition={currentPosition} />}
        {videoEnded && <GamesSection locale={locale} ref={gamesRef} />}
        {videoEnded && <Footer locale={locale} />}
      </div>
      :
      <div className="App-mobile">
        <HeaderMobile logoOverride="" focusedArea="none" isMobileAndNotMain={false} locale={locale} setLocale={setLocale} videoEnded={videoEnded} currentPosition={currentPosition} />
        <LandingSectionMobile skipVideo={skipVideo} locale={locale} videoEnded={videoEnded} setVideoEnded={setVideoEnded} currentPosition={currentPosition} ref={mainRef} />
        {videoEnded && <AboutUsSectionMobile locale={locale} currentPosition={currentPosition} ref={aboutUsRef} />}
        {videoEnded && <CompanySectionMobile locale={locale} />}
        {videoEnded && <MusicSectionMobile ref={musicRef} currentPosition={currentPosition} />}
        {videoEnded && <GamesSectionMobile locale={locale} ref={gamesRef} />}
        {videoEnded && <FooterMobile locale={locale} />}
      </div>
  )
}